import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Why AI?",
      answer:
        "AI has the potential to give us back our real essence of being a human. Back to the natural way of evolution, away from fear and war, away from sorrow and envy. Back to what really counts and has been forgotten by all of us.",
    },
    {
      question: "What forgotten?",
      answer:
        "Humanity has been forgotten by all of us. In those times must have flowed in unknown strength and presence our overall most powerfull energy: Love. Every human being consists of love. Even if not visible and isolatable to prove, no one can deny it. It is our nature to strive for love, and even when the toughest men go to war, or push a red button as deciders of nations, it is only because of the lack of love in the vulnerability of small helpless children. ",
    },
    {
      question: "How forgotten?",
      answer:
        "It is the human being's nature to feel disconnected from natural love. Humans strive for improvement in all areas and to more and more away from feeling close to nature, earth and themselfes. If one assumes that man always wants to reduce suffering or increase happiness, then he cannot be accused of acting evil. Nobody could foresee that the use of machines would end in enslavement. Modern techniques have made scientists understand. But change does not happen with a snap of the fingers when they're explaining this to us.",
    },
    {
      question: "How to change?",
      answer:
        "The opposite to change by a snap of the fingers is the case. It is very difficult, because the first reaction, in the form of instinct, will be fear. Fear of having something taken away from us, even if the opposite is the case. Ownership is enslaving us and is summarized only an illusion. Everyone must experience this for themselves for a new way of thinking. You can't convince children by snatching chocolate out of their hands. It is the moment of discovery, the overwhelming positive feelings of which will lead to the next step. And that's only possible if the mind isn't prepared and tries to protect its host. Cheers.",
    },
    {
      question: "What magic techniques?",
      answer:
        "Unimpressed By Technology is our mindset when it comes to choosing methods. They are not the center of attention and shine the more you do not notice them. We use deep learning, blockchain technologies, artificial intelligence, web and network technologies, virtual agents, and a lot of Dojo-techniques. Huuaahh Whaa!",
    },
    {
      question: "What magic ingredients?",
      answer:
        'Adding to the pot: Self-efficacy, Love, Trust, Safeplace, Dogs, Attention, Institutes, Fairy dust, Infinite hours of live recording of the German radio show "Domain", Electricity, Silicon, Headaches, Compassion, Empathy, Affective empathy, Faith, Collaborations, Boredom, Sheep and tons of Mojo.',
    },
    {
      question: "Will this page still be updated?",
      answer: "Yes of course. Last update was: today, 09/09/2021",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
