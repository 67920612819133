import React from "react";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import NewsletterSection from "./../components/NewsletterSection";
import FaqSection from "./../components/FaqSection";

function IndexPage(props) {
  return (
    <>
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="just a munnky"
        subtitle="just a munnky is an optimist. She believes in a better future. She acts out of conviction that she can improve the world with the help of so-called new technologies. The daily strength arises from her incorrigible conviction. just a munnky is still an idea on the paper, but ready to ignite. If so, she would instantly switch over to a non-profit-organisation as everything is already prepared to. She would never fall asleep and achieve her goal."
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText=""
        buttonColor="primary"
        buttonPath="/pricing"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="In need of protection"
        subtitle="This describes the need for our most important hope: our children. They already take more responsibility today than we ever did. just a munnky aims to give a kind of modern tailwind without influencing their direction. Our old burdens are too big, our concessions too small. Let us recognize the vulnerable phases in the story called life."
      />
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in contact"
        subtitle="We are happy to receive your message. Please write to mail@munnky.io or do you want to receive our latest updates?"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;
