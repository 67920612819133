import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";

function App(props) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <>
            <Navbar
              color="default"
              logo="https://static.munnky.io/logo-biggest.png"
              logoInverted="https://static.munnky.io/logo-biggest.png"
            />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bgColor="light"
              size="normal"
              bgImage=""
              bgImageOpacity={1}
              description="A short description of what you do here"
              copyright={`© ${new Date().getFullYear()} munnky GmbH`}
              logo="https://static.munnky.io/logo.png"
              logoInverted="https://static.munnky.io/logo.png"
              sticky={true}
            />
          </>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
